import * as amplitude from "@amplitude/analytics-browser";
import { initialize, updateUser } from "@iterable/web-sdk";
import { AxiosError } from "axios";
import { encode } from "next-auth/jwt";

export const track = (
	title?: string,
	eventProperties?: Record<string, any>
) => {
	if (!window.location.href.includes("localhost")) {
		amplitude.track(title || "", eventProperties);
	}
};

export const identify = async (userProps: Record<string, any>) => {

	const { setEmail } = initialize(
		process.env.NEXT_PUBLIC_ITERABLE || "",
		({ email }) => {
			const token = encode({
				token: { email: email },
				secret: process.env.NEXT_PUBLIC_AUTH_SECRET || "",
			});

			return token;
		}
	);

	const identifyEvent = new amplitude.Identify();

	for (const [key, value] of Object.entries(userProps)) {
		const newVal = Array.isArray(value)
			? value.map((val) => JSON.stringify(val))
			: value;

		identifyEvent.set(key, newVal);
	}

	amplitude.identify(identifyEvent);

	if (!userProps.email) {
		return;
	}

	await setEmail(userProps.email)
		.then()
		.catch((error: AxiosError) => {

			console.log(error);
			console.log(error?.response?.data);

		});

	updateUser({ dataFields: { ...userProps } })
		.then()
		.catch((error: AxiosError) => {

			console.log(error);
			console.log(error?.response?.data);

		});
};
