import { create } from "zustand";
import produce from "immer";
import {
	format, isBefore, isSunday, parseISO 
} from "date-fns";

import {
	User,
	Checkout,
	Pet,
	Customer,
	UserExperiments,
	UserConsent,
} from "@/types/index";
import { nextWorkingDate } from "@/helpers/helperFunctions";

import { getLocalStorage, setLocalStorage } from "./localStorage";

type UserDataStore = {
	user: Partial<User>;
	userExperiments: UserExperiments;
	userConsent: UserConsent;
	checkout: Checkout | null;
	basketOpen: boolean;
	history: string[];
	customer: Customer | null;
	activePetObjectIsValid: boolean;

	setBasketState: (open: boolean) => void;
	setCheckout: (newCheckout: Checkout | null) => void;
	resetCheckout: (newCheckout: Checkout | null) => void;
	setUser: (newUser: Partial<User>) => void;
	updateUser: (userData: Partial<User>) => void;
	updateUserExperiments: (userData: Record<string, string>) => void;
	updateUserPets: (userData: Record<string, Partial<Pet>>) => void;
	removeUserPets: (petIds: string[]) => void;
	setUserPets: (userData: Record<string, Pet>, iteration: number) => void;
	updateUserConsent: (consentData: UserConsent) => void;
	setCustomer: (newCustomer: Customer) => void;
	appendHistory: (historyItem: string) => void;
	replaceHistory: (newHistory: string[]) => void;
	setActivePetObjectValidity: (activePetObjectIsValid: boolean) => void;
};

const USER_LOCALSTORAGE_KEY = "userdata";
const CHECKOUT_LOCALSTORAGE_KEY = "userCheckoutData";
const USER_EXPERIMENTS_KEY = "userExperiments";
const CONSENT_KEY = "userConsent";

// DELAY ORDERS HERE

export const nextDeliveryAsDate = () =>
	nextWorkingDate(
		new Date(),
		isSunday(new Date())
			? 3
			: 1,
		false,
		!isSunday(new Date()),
		true
	);

export const getNextDeliveryDate = () =>
	format(nextDeliveryAsDate(), "yyyy-MM-dd");

export const useUserDataStore = create<UserDataStore>((set, get) => ({
	user: getLocalStorage(USER_LOCALSTORAGE_KEY) || {
		delivery_date: getNextDeliveryDate(),
	},

	userExperiments: getLocalStorage(USER_EXPERIMENTS_KEY) || null,

	userConsent: getLocalStorage(CONSENT_KEY) || null,

	updateUserConsent: (consentData) => {
		set(() => {
			setLocalStorage(CONSENT_KEY, consentData);
      
			return { userConsent: consentData };
		});
	},

	setUser: (userData) =>
		set(() => {
			setLocalStorage(USER_LOCALSTORAGE_KEY, userData);
      
			return {
				user: {
					...userData,
					delivery_date: getNextDeliveryDate(),
				},
			};
		}),

	updateUser: (userData) => {
		set(
			produce((state) => {
				const minDeliveryDate = nextDeliveryAsDate();
				const newData = {
					...(state.user || {}),
					delivery_date: isBefore(
						parseISO(state.user.delivery_date || "") || new Date(),
						minDeliveryDate
					)
						? format(minDeliveryDate, "yyyy-MM-dd")
						: state.user.delivery_date,
					...userData,
				};
				setLocalStorage(USER_LOCALSTORAGE_KEY, newData);
				state.user = newData;
			})
		);
	},

	updateUserExperiments: (userData) => {
		set(
			produce((state) => {
				const newState = {
					...(state.userExperiments || {}),
					...userData,
				};
				setLocalStorage(USER_EXPERIMENTS_KEY, newState);
				state.userExperiments = newState;
			})
		);
	},

	setUserPets: (userData, iteration) => {
		const petIds = Object.keys(userData).map((ids) => ids);

		const newPets = petIds.reduce((pets, id) => {
			return {
				...pets,
				[id]: {
					...(get?.()?.user?.pets?.[id] || {}),
					...userData[id],
					iteration,
				},
			};
		}, {});

		set((state) => {
			setLocalStorage(USER_LOCALSTORAGE_KEY, {
				...(state.user || {}),
				pets: {
					...state.user.pets,
					...newPets,
				},
			});

			return {
				user: {
					...(state.user || {}),
					pets: {
						...state.user.pets,
						...newPets,
					},
				},
			};
		});
	},

	updateUserPets: (userData) => {
		const petIds = Object.keys(userData).map((ids) => ids);

		const newPets = petIds.reduce((pets, id) => {
			if (!id) {
				return pets;
			}

			return {
				...pets,
				[id]: {
					...(get?.()?.user?.pets?.[id] || {}),
					...userData[id],
					// Ensure these are never overidden UNLESS they don't exist
					iteration: get?.()?.user?.pets?.[id]?.iteration ?? userData[id].iteration,
					index: get?.()?.user?.pets?.[id]?.index ?? userData[id].index,
				},
			};
		}, {});

		set((state) => {
			setLocalStorage(USER_LOCALSTORAGE_KEY, {
				...(state.user || {}),
				pets: {
					...state.user.pets,
					...newPets,
				},
			});

			return {
				user: {
					...(state.user || {}),
					pets: {
						...state.user.pets,
						...newPets,
					},
				},
			};
		});
	},

	removeUserPets: (petIds) => {
		const pets = get?.()?.user?.pets;

		if (!pets) {
			return;
		}

		const newPetObj = { ...pets };

		petIds.forEach((id) => id && newPetObj[id] && delete newPetObj[id]);

		set((state) => {
			setLocalStorage(USER_LOCALSTORAGE_KEY, {
				...(state.user || {}),
				pets: newPetObj,
			});

			return {
				user: {
					...(state.user || {}),
					pets: newPetObj,
				},
			};
		});
	},

	checkout: getLocalStorage(CHECKOUT_LOCALSTORAGE_KEY) || {
		id: "",
		pricing: {
			subtotal: 0,
			discount: 0,
			total: 0,
			// PLACEHOLDER FOR BEFORE CHECKOUT IS FETCHED. UPDATE HERE
			delivery_threshold: 1000,
		},
		lineItems: [],
	},
	setCheckout: (checkoutData) =>
		set((state) => {
			const newData = { ...state.checkout,
				...checkoutData };
			setLocalStorage(CHECKOUT_LOCALSTORAGE_KEY, newData);

			return { checkout: newData };
		}),
	resetCheckout: (checkoutData) =>
		set(() => {
			setLocalStorage(CHECKOUT_LOCALSTORAGE_KEY, checkoutData);

			return { checkout: checkoutData };
		}),
	basketOpen: false,
	toggleBasketOpen: () => set((state) => ({ basketOpen: !state.basketOpen })),
	setBasketState: (basketState) => set(() => ({ basketOpen: basketState })),

	customer: null,
	setCustomer: (customer) => set(() => ({ customer })),

	history: [],
	appendHistory: (historyItem) =>
		set((state) => ({ history: [...state.history, historyItem] })),
	replaceHistory: (newHistory) => set(() => ({ history: newHistory })),

	activePetObjectIsValid: true,
	setActivePetObjectValidity: (activePetObjectIsValid: boolean) =>
		set(() => ({ activePetObjectIsValid })),
}));

// if (process.env.NODE_ENV === "development") {
// mountStoreDevtool("Store", useUserDataStore);
// }
