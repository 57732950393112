import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
	mutation LoginCustomer($customer: CustomerLoginInput) {
		LoginCustomer(customer: $customer) {
			id
			token
			first_name
			last_name
			phone
			email
			created_at
			updated_at
		}
	}
`;

export const GENERATE_LOGIN_TOKEN = gql`
	mutation GenerateSignupLoginToken(
		$id: Int,
		$first_name: String,
		$last_name: String,
		$email: String
	) {
		GenerateSignupLoginToken(
		id: $id ,
		first_name: $first_name,
		last_name: $last_name,
		email: $email
		) {
			token
		}
	}
`;

