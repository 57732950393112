// TODO type this file
// @ts-nocheck

import {
	ActivityLevelTime,
	BodyShape,
	LifeStages,
	TreatsQuantity,
} from "@/types/index";

import { initializeApollo } from "./apolloClient";
import { FEEDING_QUERY } from "./queries";

import { OLD_PLAN_SIZES } from "../consts";


export type FeedingGuideType = {
	base_calories: number;
	calories_per_day: number;
	required_calories: number;
	scoop_amount: number;
	treat_calories: number;
};

export type CalculateFeedingGuideReturn = {
	feedingGuide: {
		new: FeedingGuideType;
		old: {
			food: string;
			meat: string;
			type: string;
			water: string;
			weight: string;
		}
	}
}

export async function calculateFeedingGuide (
	age: number,
	weight: number,
	activity: ActivityLevelTime,
	shape: BodyShape,
	breed: string,
	type: "sub" | "cat" | "dog",
	neutered: boolean = false,
	treats: TreatsQuantity | null = "a couple of",
	lifestage: LifeStages | null = "adult"
): Promise<CalculateFeedingGuideReturn["feedingGuide"]["new"]> {
	const activityArray = [
		"not very active",
		"active",
		"very active"
	];
	const newActivityArray = [
		"up to an hour",
		"1-2 hours",
		"over 2 hours"
	];
	const stepActivityArray = [
		"0-30 mins",
		"30-60 mins",
		"1-2 hours",
		"2+ hours",
	];

	let activity_level;

	if (stepActivityArray.includes(activity)) {
		const index = Math.max(0, stepActivityArray.indexOf(activity) - 1);
		activity_level = activityArray[index];
	} else if (!activityArray.includes(activity)) {
		const index = newActivityArray.indexOf(activity);
		activity_level = activityArray[index];
	}

	const feedingVariables = {
		type: type,
		pet: {
			age: Math.round(parseFloat(age)),
			weight: parseFloat(weight),
			breed: breed,
			activity_level: activity_level ?? activity,
			size: shape,
			neutered: neutered,
			treats_fed: treats,
			life_stage: lifestage,
		},
	};
	const apolloClient = initializeApollo();
	
	return await apolloClient
		.query({
			query: FEEDING_QUERY,
			variables: feedingVariables,
			context: { clientName: "subApp" },
		})
		.then((result: CalculateFeedingGuideReturn) => {
			return result.data.feedingGuide.new;
		});
}

export type PackWeight = [
	fullPack: number,
	fullLength: number,
	trialPack: number,
	trialLength: number,
	dailyFeed: number
];

export function calculatePackWeights (
	type: "sub" | "cat" | "Dog",
	scoops: number,
	version = "old"
): PackWeight {
	const trialWeights = [
		465,
		925,
		1400,
		1850,
		2325,
		2800,
		3250,
		3700
	];

	if (version === "old") {
		var packWeights = OLD_PLAN_SIZES;
	} else if (type === "sub") {
		var packWeights = [
			925,
			1850,
			2800,
			3700,
			4650,
			5600,
			6500,
			7400
		];
	} else if (type === "cat") {
		var packWeights = [1000, 2000];
	} else {
		var packWeights = [
			2000,
			4000,
			8000
		];
	}

	const scoopWeight = 33;
	const dailyFeed = parseFloat(scoops) * scoopWeight;
	const fullGoal = dailyFeed * 28;
	const fullPack = packWeights.reduce(function (prev, curr) {
		return Math.abs(curr - fullGoal) < Math.abs(prev - fullGoal)
			? curr
			: prev;
	});
	const fullLength = Math.floor((fullPack / dailyFeed).toFixed(1));

	const trialGoal = fullGoal / 2;
	const trialPack = trialWeights.reduce(function (prev, curr) {
		return Math.abs(curr - trialGoal) < Math.abs(prev - trialGoal)
			? curr
			: prev;
	});
	const trialLength = Math.floor((trialPack / dailyFeed).toFixed(1));

	return [
		fullPack,
		fullLength,
		trialPack,
		trialLength,
		dailyFeed
	];
}
